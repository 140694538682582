/* Animate css */
@import './assets/css/animate.css';

/* tippy css */
@import './assets/css/tippy.css';

/* swiper css */
@import './assets/css/swiper.css';

/* Elements - Progressbar */
@import './assets/css/progressbar.css';

/* Data tables css */
@import './assets/css/datatables.css';

/* scrumboard */
@import './assets/css/scrumboard.css';

/* calendar css */
@import './assets/css/fullcalendar.css';

/* sweetalert css */
@import './assets/css/sweetalert.css';

/* flatpickr css */
@import './assets/css/flatpickr.css';

/* quill-editor css */
@import './assets/css/quill-editor.css';

/* markdown-editor css */
@import './assets/css/markdown-editor.css';

/* file upload with preview */
@import './assets/css/file-upload-preview.css';

/* dragndrop*/
@import './assets/css/dragndrop.css';

/* form element*/
@import './assets/css/form-elements.css';

/* Select2*/
@import './assets/css/select2.css';

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;700;900&display=swap");

body {
  @apply leading-[normal] m-0;
}

*,
::before,
::after {
  border-width: 0;
}
input,
textarea,
select {
  outline: none;
  flex: 1;
  width: 100%;
  
  font-family: inherit;
  font-size: inherit;
  background-color: transparent;
}

&::placeholder {
  color: var(--gray-color);
  font-family: inherit;
  font-size: inherit;
}
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    html {
        @apply scroll-smooth;
    }

    body {
        @apply bg-[#fafafa] font-nunito;
    }

    body.dark {
        @apply bg-[#060818];
    }

    /* Panel */
    .panel {
        @apply relative rounded-md bg-white p-5 shadow dark:bg-black;
    }

    /* Navbar */
    .navbar-sticky header,
    .navbar-floating header {
        @apply sticky top-0 z-20;
    }
    .navbar-floating header {
        @apply bg-[#fafafa]/90 px-6 pt-4 dark:bg-[#060818]/90;
    }
    .navbar-floating header > div > div:first-child {
        @apply rounded-md;
    }
    .horizontal .navbar-floating header > div > div:first-child {
        @apply rounded-b-none;
    }
    .horizontal .navbar-floating header .horizontal-menu {
        @apply rounded-b-md;
    }

    /* Sidebar */
    .sidebar:hover .nav-item > a {
        @apply w-auto;
    }

    .sidebar .nav-item > button,
    .sidebar .nav-item > a {
        @apply mb-1 flex w-full items-center justify-between overflow-hidden whitespace-nowrap rounded-md p-2.5 text-[#506690] hover:bg-[#000]/[0.08] hover:text-black dark:hover:bg-primary dark:hover:text-white-dark;
    }
    .sidebar .nav-item > button.active,
    .sidebar .nav-item > a.active {
        @apply bg-secondary text-white dark:bg-primary dark:text-white-dark;
    }

    .sidebar .nav-item > button.active > div > span,
    .sidebar .nav-item > a.active > div > span {
        @apply dark:!text-white-dark;
    }

    .sidebar ul.sub-menu li button,
    .sidebar ul.sub-menu li a {
        @apply flex w-full items-center px-9 py-2.5 before:h-0.5 before:w-2 before:rounded before:bg-gray-300 hover:bg-gray-100
hover:text-white  hover:before:!bg-primary ltr:before:mr-2 rtl:before:ml-2 dark:before:bg-gray-500 dark:hover:bg-primary dark:hover:text-white;
    }
    .sidebar ul.sub-menu li button.active,
    .sidebar ul.sub-menu li a.active {
        @apply text-white before:bg-primary;
    }

    .sidebar .nav-item a div:first-child svg,
    .sidebar .nav-item button div:first-child svg {
        @apply h-5 w-5 text-black/50 dark:text-white/50;
    }

    .main-container .main-content {
        @apply transition-all duration-300 lg:ltr:ml-[260px] lg:rtl:mr-[260px];
    }

    /* Horizontal layouts */
    .horizontal .horizontal-menu {
        @apply hidden shadow-md lg:flex;
    }
    .horizontal .horizontal-logo {
        @apply flex;
    }
    .horizontal .main-container .main-content {
        @apply ltr:ml-0 rtl:mr-0;
    }
    .horizontal .sidebar {
        @apply ltr:-left-[260px] rtl:-right-[260px];
    }
    .horizontal.toggle-sidebar .sidebar {
        @apply ltr:left-0 rtl:right-0 lg:ltr:-left-[260px] lg:rtl:-right-[260px];
    }

    .horizontal .nav-item a div:first-child svg,
    .horizontal .nav-item button div:first-child svg {
        @apply w-5 h-5 text-black/50 dark:text-white/50;
    }

    .horizontal .dark .nav-item button div:first-child svg,
    .dark.horizontal .nav-item button div:first-child svg {
        @apply text-white/50;
    }

    .horizontal-menu .nav-link {
        @apply flex items-center py-2.5 px-2 rounded-lg hover:bg-secondary hover:text-black dark:hover:bg-secondary dark:hover:text-white-dark xl:px-4;
    }

    .horizontal-menu .nav-link.active {
        @apply bg-[#000]/[0.08] text-black dark:bg-secondary dark:text-white-dark;
    }

    .horizontal-menu ul.sub-menu {
        @apply absolute top-full z-[10] hidden min-w-[180px] rounded bg-white p-0 py-2 text-dark shadow dark:bg-secondary dark:text-white-dark;
    }

    .horizontal-menu ul.sub-menu a,
    .horizontal-menu ul.sub-menu button {
        @apply flex items-center justify-between px-4 py-2 hover:bg-gray-100 hover:text-primary dark:hover:bg-primary/10 w-full;
    }

    .horizontal-menu ul.sub-menu a.active,
    .horizontal-menu ul.sub-menu button.active {
        @apply bg-gray-100 text-primary dark:bg-primary/10
        ;
    }

    .horizontal-menu > li.nav-item:hover > ul.sub-menu,
    .horizontal-menu > li.nav-item > ul.sub-menu > li:hover > ul {
        @apply block;
    }

    /* Vertical layouts */
    .vertical.toggle-sidebar .horizontal-logo,
    .vertical.toggle-sidebar .collapse-icon {
        @apply flex;
    }
    .vertical.toggle-sidebar .main-container .main-content {
        @apply ltr:ml-0 rtl:mr-0;
    }
    .vertical .sidebar {
        @apply ltr:-left-[260px] rtl:-right-[260px] lg:ltr:left-0 lg:rtl:right-0;
    }
    .vertical.toggle-sidebar .sidebar {
        @apply ltr:left-0 rtl:right-0 lg:ltr:-left-[260px] lg:rtl:-right-[260px];
    }

    /* Collapsible vertical layouts */
    .collapsible-vertical .sidebar {
        @apply hover:w-[260px] ltr:-left-[260px] rtl:-right-[260px] lg:w-[70px] lg:ltr:left-0 lg:rtl:right-0;
    }
    .collapsible-vertical.toggle-sidebar .sidebar {
        @apply ltr:left-0 rtl:right-0;
    }
    .collapsible-vertical.toggle-sidebar .sidebar {
        @apply lg:w-[260px];
    }
    .collapsible-vertical.toggle-sidebar .sidebar .nav-item > a {
        @apply w-auto;
    }
    .collapsible-vertical.toggle-sidebar .main-content {
        @apply lg:w-[calc(100%-260px)] lg:ltr:ml-[260px] lg:rtl:mr-[260px];
    }

    .collapsible-vertical .sidebar .sub-menu {
        @apply lg:hidden;
    }
    .collapsible-vertical .sidebar:hover .sub-menu,
    .collapsible-vertical .sidebar:hover .sub-menu.recent-submenu,
    .collapsible-vertical.toggle-sidebar .sidebar .sub-menu {
        @apply block;
    }
    .collapsible-vertical .main-content {
        @apply lg:w-[calc(100%-70px)] lg:ltr:ml-[70px] lg:rtl:mr-[70px];
    }
    .collapsible-vertical .sidebar .collapse-icon,
    .collapsible-vertical .main-logo > span {
        @apply transition-opacity duration-300 lg:opacity-0;
    }
    .collapsible-vertical .sidebar:hover .collapse-icon,
    .collapsible-vertical.toggle-sidebar .collapse-icon,
    .collapsible-vertical .sidebar:hover .main-logo > span,
    .collapsible-vertical.toggle-sidebar .main-logo > span {
        @apply duration-500 lg:opacity-100;
    }
    .collapsible-vertical.toggle-sidebar .sidebar .collapse-icon {
        @apply flex rotate-0;
    }
    .collapsible-vertical .sidebar:hover .collapse-icon {
        @apply flex rotate-180;
    }
    .collapsible-vertical .sidebar ul > h2 span {
        @apply hidden whitespace-nowrap;
    }
    .collapsible-vertical .sidebar ul > h2 svg {
        @apply block;
    }
    .collapsible-vertical .sidebar:hover ul > h2 span,
    .collapsible-vertical.toggle-sidebar .sidebar ul > h2 span {
        @apply inline;
    }
    .collapsible-vertical .sidebar:hover ul > h2 svg,
    .collapsible-vertical.toggle-sidebar .sidebar ul > h2 svg {
        @apply hidden;
    }

    /* boxed-layout */
    .boxed-layout {
        @apply mx-auto max-w-[1400px];
    }

    .boxed-layout.vertical .sidebar,
    .boxed-layout.collapsible-vertical .sidebar {
        @apply overflow-hidden lg:ltr:left-auto lg:rtl:right-auto;
    }

    .boxed-layout.vertical.toggle-sidebar .sidebar {
        @apply lg:w-0;
    }

    /* Buttons */
    .btn {
        @apply relative flex items-center justify-center rounded-md border px-5 py-2 text-sm font-semibold shadow-[0_10px_20px_-10px] outline-none transition duration-300 hover:shadow-none;
    }
    .btn-lg {
        @apply px-7 py-2.5 text-base;
    }
    .btn-sm {
        @apply px-2.5 py-1.5 text-xs;
    }
    .btn[disabled] {
        @apply cursor-not-allowed opacity-60;
    }

    .btn-primary {
        @apply border-primary bg-primary text-white shadow-primary/60;
    }
    .btn-outline-primary {
        @apply border-primary text-primary shadow-none hover:bg-primary hover:text-white;
    }

    .btn-secondary {
        @apply border-secondary bg-secondary text-white shadow-secondary/60;
    }
    .btn-outline-secondary {
        @apply border-secondary text-secondary shadow-none hover:bg-secondary hover:text-white;
    }

    .btn-success {
        @apply border-success bg-success text-white shadow-success/60;
    }
    .btn-outline-success {
        @apply border-success text-success shadow-none hover:bg-success hover:text-white;
    }

    .btn-danger {
        @apply border-danger bg-danger text-white shadow-danger/60;
    }
    .btn-outline-danger {
        @apply border-danger text-danger shadow-none hover:bg-danger hover:text-white;
    }

    .btn-warning {
        @apply border-warning bg-warning text-white shadow-warning/60;
    }
    .btn-outline-warning {
        @apply border-warning text-warning shadow-none hover:bg-warning hover:text-white;
    }

    .btn-info {
        @apply border-info bg-info text-white shadow-info/60;
    }
    .btn-outline-info {
        @apply border-info text-info shadow-none hover:bg-info hover:text-white;
    }

    .btn-dark {
        @apply border-dark bg-dark text-white shadow-dark/60;
    }

    .btn-outline-dark {
        @apply border-dark text-dark shadow-none hover:bg-dark hover:text-white;
    }

    .btn-gradient {
        @apply bg-gradient-to-r from-[#EF1262] to-[#4361EE] hover:to-[#EF1262] hover:from-[#4361EE] text-white;
    }

    /* Badge */
    .badge {
        @apply relative my-1 rounded  border-transparent px-2 py-0.5 text-xs font-semibold text-white;
    }
    .badge-outline-primary {
        @apply border-primary text-primary hover:bg-primary-light dark:hover:bg-primary dark:hover:text-white-light;
    }
    .badge-outline-secondary {
        @apply border-secondary text-secondary hover:bg-secondary-light dark:hover:bg-secondary dark:hover:text-white-light;
    }
    .badge-outline-success {
        @apply border-success text-success hover:bg-success-light dark:hover:bg-success dark:hover:text-white-light;
    }
    .badge-outline-danger {
        @apply border-danger text-danger hover:bg-danger-light dark:hover:bg-danger dark:hover:text-white-light;
    }
    .badge-outline-warning {
        @apply border-warning text-warning hover:bg-warning-light dark:hover:bg-warning dark:hover:text-white-light;
    }
    .badge-outline-info {
        @apply border-info text-info hover:bg-info-light dark:hover:bg-info dark:hover:text-white-light;
    }
    .badge-outline-dark {
        @apply border-dark text-dark hover:bg-dark-light dark:hover:bg-dark dark:hover:text-white-light;
    }

    /* Form */
    .form-input,
    .form-textarea,
    .form-select,
    .form-multiselect {
        @apply w-full rounded-md border border-white-light bg-white px-4 py-2 text-sm  text-gray-600 !outline-none focus:border-primary focus:ring-transparent dark:border-[#17263c] dark:bg-[#121e32] dark:text-gray-400 dark:focus:border-primary;
    }

    .form-input-lg,
    .form-textarea-lg,
    .form-select-lg,
    .form-multiselect-lg {
        @apply py-2.5 text-base;
    }
    .form-input-sm,
    .form-textarea-sm,
    .form-select-sm,
    .form-multiselect-sm {
        @apply py-1.5 text-xs;
    }
    label {
        @apply mb-1.5 block font-semibold;
    }
    [dir='rtl'] select {
        background-position: left 0.5rem center;
    }

    .has-error .form-input,
    .has-error .form-textarea,
    .has-error .form-select,
    .has-error .form-multiselect,
    .has-error .multiselect__tags {
        @apply border-danger bg-danger/[0.08] text-danger placeholder-danger/70 focus:border-danger;
    }
    .has-error .form-label,
    .has-error .form-help,
    .has-error .form-icon,
    .has-error .multiselect__placeholder {
        @apply text-danger;
    }
    .has-error .multiselect__input {
        @apply bg-[#F7ECF0] !placeholder-danger/70;
    }
    .has-error .multiselect__tags:hover,
    .has-error .form-checkbox {
        @apply border-danger;
    }

    .has-success .form-input,
    .has-success .form-textarea,
    .has-success .form-select,
    .has-success .form-multiselect,
    .has-success .multiselect__tags {
        @apply border-success bg-success/[0.08] text-success placeholder-success/70 focus:border-success;
    }
    .has-success .form-label,
    .has-success .form-help,
    .has-success .form-icon,
    .has-success .multiselect__placeholder {
        @apply text-success;
    }
    .has-success .multiselect__input {
        @apply bg-[#F7ECF0] !placeholder-success/70;
    }
    .has-success .multiselect__tags:hover {
        @apply border-success;
    }

    /* checkbox & radio */
    .form-radio,
    .form-checkbox {
        @apply h-5 w-5 cursor-pointer rounded border-2 border-white-light bg-transparent text-primary !shadow-none !outline-none !ring-0 !ring-offset-0 checked:bg-[length:90%_90%] disabled:cursor-not-allowed disabled:bg-[#eee] ltr:mr-1.5 rtl:ml-1.5
      dark:border-[#253b5c] dark:checked:border-transparent dark:disabled:bg-[#1b2e4b];
    }

    .form-checkbox.outline-primary:checked {
        @apply border-primary bg-transparent;
        background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%234361ee' xmlns='http://www.w3.org/2000/svg'><path d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/></svg>");
    }
    .form-checkbox.outline-secondary:checked {
        @apply border-secondary bg-transparent;
        background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23805dca' xmlns='http://www.w3.org/2000/svg'><path d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/></svg>");
    }
    .form-checkbox.outline-success:checked {
        @apply border-success bg-transparent;
        background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%2300ab55' xmlns='http://www.w3.org/2000/svg'><path d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/></svg>");
    }
    .form-checkbox.outline-danger:checked {
        @apply border-danger bg-transparent;
        background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23e7515a' xmlns='http://www.w3.org/2000/svg'><path d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/></svg>");
    }
    .form-checkbox.outline-warning:checked {
        @apply border-warning bg-transparent;
        background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23e2a03f' xmlns='http://www.w3.org/2000/svg'><path d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/></svg>");
    }
    .form-checkbox.outline-info:checked {
        @apply border-info bg-transparent;
        background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%232196f3' xmlns='http://www.w3.org/2000/svg'><path d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/></svg>");
    }
    .form-checkbox.outline-dark:checked {
        @apply border-dark bg-transparent;
        background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%233b3f5c' xmlns='http://www.w3.org/2000/svg'><path d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/></svg>");
    }

    .form-radio {
        @apply rounded-full;
    }

    .form-radio.outline-primary:checked {
        @apply border-primary bg-transparent bg-none;
    }
    .form-radio.outline-secondary:checked {
        @apply border-secondary bg-transparent bg-none;
    }
    .form-radio.outline-success:checked {
        @apply border-success bg-transparent bg-none;
    }
    .form-radio.outline-danger:checked {
        @apply border-danger bg-transparent bg-none;
    }
    .form-radio.outline-warning:checked {
        @apply border-warning bg-transparent bg-none;
    }
    .form-radio.outline-info:checked {
        @apply border-info bg-transparent bg-none;
    }
    .form-radio.outline-dark:checked {
        @apply border-dark bg-transparent bg-none;
    }

    /* dropdown */
    .dropdown {
        @apply relative;
    }
    .dropdown > button {
        @apply flex;
    }
    .dropdown ul {
        @apply my-1 min-w-[120px] rounded bg-white p-0 py-2 shadow dark:bg-[#1b2e4b] text-black dark:text-white-dark;
    }
    .dropdown ul li > a,
    .dropdown ul li > button {
        @apply flex items-center px-4 py-2 hover:bg-primary/10 hover:text-primary;
    }
    .dropdown ul li > button {
        @apply w-full;
    }

    /* tables */
    .table-responsive {
        @apply overflow-auto;
    }
    table {
        @apply w-full !border-collapse;
    }
    table thead tr,
    table tfoot tr {
        @apply border-b-0 !bg-[#f6f8fa]  dark:!bg-[#1a2941];
    }
    table thead tr th,
    table tfoot tr th,
    table tbody tr td {
        @apply py-3 px-4 ltr:text-left rtl:text-right;
    }
    table thead tr th,
    table tfoot tr th {
        @apply font-semibold;
    }
    table tbody tr {
        @apply border-b !border-white-light/40 dark:!border-[#191e3a];
    }
    table.table-hover tbody tr {
        @apply hover:!bg-white-light/20 dark:hover:!bg-[#1a2941]/40;
    }
    table.table-striped tbody tr:nth-child(odd) {
        @apply !bg-white-light/20 dark:!bg-[#1a2941]/40;
    }

    table.dataTable-table tbody tr th,
    table.dataTable-table tbody tr td {
        @apply border-b border-white-light/40 py-3 px-4 ltr:text-left rtl:text-right dark:border-[#191e3a];
    }
    table.dataTable-table tbody tr:last-child td {
        @apply border-b-0;
    }

    /* code hightlight */
    pre {
        direction: ltr;
    }
}

/* perfect scrollbar */
.ps__rail-y > .ps__thumb-y,
.ps__rail-y > .ps__thumb-y {
    @apply !w-1.5 !bg-[#DDDDDD] dark:!bg-[#2d334c];
}
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    @apply !opacity-60;
}
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    @apply !bg-transparent;
}

/* swal2 */
.swal2-container .swal2-close {
    @apply text-white hover:text-dark-light focus:shadow-none;
}

.swal2-container .swal2-popup.swal2-toast {
    @apply bg-dark py-2.5 px-5;
}

.swal2-popup.swal2-toast .swal2-title,
.swal2-container .swal2-popup.swal2-toast .swal2-html-container {
    @apply text-white;
}
.swal2-container .swal2-popup.swal2-toast.color-primary {
    @apply bg-primary;
}

.swal2-container .swal2-popup.swal2-toast.color-secondary {
    @apply bg-secondary;
}

.swal2-container .swal2-popup.swal2-toast.color-warning {
    @apply bg-warning;
}
.swal2-container .swal2-popup.swal2-toast.color-info {
    @apply bg-info;
}
.swal2-container .swal2-popup.swal2-toast.color-danger {
    @apply bg-danger;
}
.swal2-container .swal2-popup.swal2-toast.color-success {
    @apply bg-success;
}

/* apex chart */
.apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-light,
.apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-light {
    box-shadow: none;
    @apply border-[#050717cc] bg-[#050717cc] text-white;
}

.apexcharts-canvas .apexcharts-xaxistooltip-bottom:before,
.apexcharts-canvas .apexcharts-xaxistooltip-bottom:after {
    @apply border-b-[#050717cc];
}

.apexcharts-canvas .apexcharts-tooltip-series-group.apexcharts-active {
    @apply text-white;
}

.apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    @apply border-dark bg-[#060818];
}

.apexcharts-legend-series {
    @apply ltr:!mr-2 rtl:!ml-2;
}

.dark .apexcharts-title-text {
    fill: #e0e6ed;
}

.dark .apexcharts-canvas .apexcharts-text.apexcharts-xaxis-label,
.dark .apexcharts-canvas .apexcharts-text.apexcharts-yaxis-label {
    fill: #e0e6ed;
}

.dark .apexcharts-canvas .apexcharts-text,
.dark .apexcharts-canvas .apexcharts-text {
    fill: #e0e6ed;
}

.dark .apexcharts-canvas .apexcharts-legend-text {
    color: #e0e6ed !important;
}

.dark .apexcharts-canvas .apexcharts-radialbar-track.apexcharts-track .apexcharts-radialbar-area {
    stroke: #191e3a;
}
.dark .apexcharts-canvas .apexcharts-series-markers.apexcharts-series-bubble .apexcharts-marker {
    stroke: #191e3a;
}

.dark .apexcharts-canvas .apexcharts-pie-label,
.dark .apexcharts-canvas .apexcharts-datalabel,
.dark .apexcharts-canvas .apexcharts-datalabel-label,
.dark .apexcharts-canvas .apexcharts-datalabel-value {
    fill: #bfc9d4;
}

.dark .apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-dark {
    box-shadow: none;
}

.apexcharts-canvas .apexcharts-legend-marker {
    @apply ltr:!mr-1.5 rtl:ml-1.5 rtl:!mr-0;
}

[dir='rtl'] .apexcharts-tooltip-marker {
    @apply mr-0 ml-2.5;
}

/* Animations */
.slide-down-enter-active {
    @apply transition duration-100 ease-out;
}
.slide-down-leave-active {
    @apply transition duration-75 ease-in;
}
.slide-down-enter-from,
.slide-down-leave-to {
    @apply transform scale-95 opacity-0;
}
.slide-down-enter-to,
.slide-down-leave-from {
    @apply transform scale-100 opacity-100;
}

.modal-fade-enter-active {
    @apply transition duration-300 ease-out;
}
.modal-fade-leave-active {
    @apply transition duration-200 ease-in;
}
.modal-fade-enter-from,
.modal-fade-leave-to {
    @apply transform opacity-0 scale-95;
}
.modal-fade-enter-to,
.modal-fade-leave-from {
    @apply transform opacity-100 scale-100;
}

/* Hightlight JS */
pre.hljs {
    @apply p-6 rounded-md overflow-x-auto !bg-[#191e3a];
}


h1{
    font-size: 3em;
    line-height:1em;
}

h2{
    font-size: 2em;
    line-height:0.8em;
}

h2{
    font-size: 1em;
    line-height:0.7em;
}

h4{
    font-size: 0.8em;
    line-height:0.6em;
}
.file-upload-container {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.file-list {
    list-style: none;
    padding: 0;
}

.file-item {
    margin-bottom: 10px;
}

.file-item a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
}

.file-type {
    margin-left: 10px;
    color: #666;
    font-size: 14px;
}


  .website-agency-home-child {
    width: 677px;
    display: none;
    max-width: 100%;
    z-index: 1;
  }
  .welcome-to-aims {
    margin: 0;
    flex: 1;
    position: relative;
    font-size: inherit;
    line-height: 40px;
    text-transform: capitalize;
    font-weight: 700;
    font-family: inherit;
    z-index: 1;
  }
  .welcome-to-aims-wrapper {
    width: 256px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 8px;
    box-sizing: border-box;
  }
  .explore-exciting-opportunities,
  .join-us-on {
    position: relative;
    text-transform: capitalize;
    display: inline-block;
    z-index: 1;
  }
  .explore-exciting-opportunities {
    margin: 0;
    width: 537px;
    font-size: inherit;
    line-height: 42px;
    font-weight: 900;
    font-family: inherit;
    max-width: 100%;
  }
  .join-us-on {
    width: 677px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
    max-width: 115%;
    flex-shrink: 0;
  }
  .website-agency-home {
    width: 50%;
    background-color: #000;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 577px 74px 100px;
    box-sizing: border-box;
    position: relative;
    gap: 4px;
    min-width: 735px;
    max-width: 100%;
    text-align: left;
    font-size: 32px;
    color: #fff;
    font-family: Outfit;
  }
  @media screen and (max-width: 1250px) {
    .website-agency-home {
      min-width: 40%;
    }
  }
  @media screen and (max-width: 1050px) {
    .website-agency-home {
      padding-left: 37px;
      padding-right: 37px;
      box-sizing: border-box;
      min-width: 50%;
    }
  }
  @media screen and (max-width: 750px) {
    .welcome-to-aims {
      font-size: 26px;
      line-height: 32px;
    }
    .explore-exciting-opportunities {
      font-size: 26px;
      line-height: 34px;
    }
    .website-agency-home {
     display: none;
    }
  }
  @media screen and (max-width: 450px) {
    .welcome-to-aims {
      font-size: 19px;
      line-height: 24px;
    }
    .explore-exciting-opportunities {
      font-size: 19px;
      line-height: 25px;
    }
    .join-us-on {
      font-size: 16px;
      line-height: 26px;
    }
  }
  .aims-logo-2-1-icon {
    width: 326px;
    height: 76px;
    position: relative;
    object-fit: cover;
    max-width: 100%;
  }
  .fill-in-your,
  .welcome-back {
    position: relative;
    text-transform: capitalize;
  }
  .welcome-back {
    margin: 0;
    align-self: stretch;
    font-size: inherit;
    font-weight: 900;
    font-family: inherit;
  }
  .fill-in-your {
    width: 176px;
    font-size: 18px;
    color: #535353;
    display: inline-block;
  }
  .username-input {
    width: 351px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 20px 0 0;
    box-sizing: border-box;
    gap: 12px;
    max-width: 100%;
  }
  .email-or-username {
    width: 163px;
    position: relative;
    font-size: 16px;
    text-transform: capitalize;
    font-family: Outfit;
    color: #535353;
    white-space: pre-wrap;
    text-align: center;
    display: inline-block;
    box-sizing: border-box;
    padding-right: 20px;
  }
  .contact-us,
  .hide-button {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .hide-button {
    width: 100%;
    border: 0;
    outline: 0;
    background-color: #eeeded;
    height: 48px;
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;
    flex-direction: row;
    padding: 12px 16px;
    box-sizing: border-box;
    font-family: Outfit;
    font-size: 16px;
    color: #afafaf;
    min-width: 223px;
  }
  .contact-us {
    flex-direction: column;
    gap: 12px;
  }
  .password {
    width: 98px;
    position: relative;
    font-size: 16px;
    text-transform: capitalize;
    font-family: Outfit;
    color: #535353;
    text-align: left;
    display: inline-block;
    box-sizing: border-box;
    padding-right: 20px;
  }
  .enter-passsword,
  .hide-icon {
    height: 24px;
    position: relative;
  }
  .enter-passsword {
    width: calc(100% - 56px);
    border: 0;
    outline: 0;
    font-family: Outfit;
    font-size: 16px;
    background-color: transparent;
    flex: 1;
    text-transform: capitalize;
    color: #afafaf;
    text-align: left;
    display: flex;
    align-items: center;
    min-width: 150px;
    padding: 0;
  }
  .hide-icon {
    width: 24px;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 24px;
  }
  .enter-passsword-parent,
  .product,
  .turning-ideas-into-intuitive-d {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .enter-passsword-parent {
    flex: 1;
    border-radius: 8px;
    background-color: #eeeded;
    overflow: hidden;
    flex-direction: row;
    padding: 12px 16px;
    gap: 10px;
  }
  .product,
  .turning-ideas-into-intuitive-d {
    flex-direction: column;
  }
  .turning-ideas-into-intuitive-d {
    flex: 1;
    gap: 12px;
  }
  .product {
    height: 176px;
    gap: 16px;
  }
  .divider {
    height: 19px;
    width: 19px;
    position: relative;
    border-radius: 8px;
    border: 1px solid #8d8b8b;
    box-sizing: border-box;
  }
  .remember-me {
    flex: 1;
    position: relative;
    font-size: 16px;
    text-transform: capitalize;
    font-family: Outfit;
    color: #0c0c0c;
    text-align: center;
    display: inline-block;
    min-width: 105px;
  }
  .divider-parent {
    width: 132px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  .forgot-password {
    width: 131px;
    position: relative;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
    font-family: Outfit;
    color: #000;
    text-align: center;
    display: inline-block;
  }
  .frame-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
  }
  .log-in1 {
    flex: 1;
    position: relative;
    font-size: 16px;
    text-transform: capitalize;
    font-family: Outfit;
    color: #fff;
    text-align: center;
    display: inline-block;
   
  }
  .contact-us1 {
    cursor: pointer;
    border: 0;
    padding: 12px 16px;
    background-color: #f15a29;
    align-self: stretch;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    white-space: nowrap;
    max-width: 100%;
  }
  .contact-us1:hover {
    background-color: #d9400f;
  }
  .dont-have-account,
  .sign-up {
    position: relative;
    font-size: 16px;
    text-transform: capitalize;
    font-family: Outfit;
    text-align: center;
  }
  .dont-have-account {
    flex: 1;
    color: #5d5d5d;
  }
  .sign-up {
    width: 55px;
    font-weight: 500;
    color: #000;
    display: inline-block;
    min-width: 55px;
    white-space: nowrap;
  }
  .new-user-call-to-action,
  .new-user-call-to-action-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .new-user-call-to-action {
    flex: 1;
    gap: 8px;
  }
  .new-user-call-to-action-wrapper {
    align-self: stretch;
    padding: 0 80px;
  }
  .contact-us-parent,
  .forgot-pass-link {
    align-self: stretch;
    flex-direction: column;
  }
  .contact-us-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    max-width: 100%;
  }
  .forgot-pass-link {
    margin: 0;
    gap: 32px;
  }
  .aims-logo-2-1-parent,
  .forgot-pass-link,
  .frame-wrapper,
  .username-input-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .username-input-parent {
    flex: 1;
    flex-direction: column;
    gap: 40px;
  }
  .aims-logo-2-1-parent,
  .frame-wrapper {
    align-self: stretch;
  }
  .frame-wrapper {
    flex-direction: row;
    padding: 0 0 0 31px;
    box-sizing: border-box;
  }
  .aims-logo-2-1-parent {
    flex-direction: column;
    gap: 89px;
  }
  .log-in,
  .log-in-inner {
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
  }
  .log-in-inner {
    width: 403px;
    flex-direction: column;
    justify-content: flex-start;
    padding: 32px 0 0;
    min-width: 403px;
    max-width: 100%;
  }
  .log-in {
    width: 100%;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 0 82px;
    letter-spacing: normal;
    gap: 20px;
    text-align: center;
    font-size: 48px;
    color: #f15a29;
    font-family: Outfit;
  }
  @media screen and (max-width: 1250px) {
    .log-in-inner {
      flex: 1;
    }
    .log-in {
      flex-wrap: wrap;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 750px) {
    .welcome-back {
      font-size: 38px;
    }
    .log-in-inner {
      min-width: 100%;
    }
  }
  @media screen and (max-width: 450px) {
    .welcome-back {
      font-size: 29px;
    }
    .frame-parent {
      flex-wrap: wrap;
    }
    .new-user-call-to-action-wrapper {
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;
    }
    .forgot-pass-link {
      gap: 16px 32px;
    }
    .username-input-parent {
      gap: 20px 40px;
    }
    .aims-logo-2-1-parent {
      gap: 44px 89px;
    }
  }